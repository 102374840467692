import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../config/api.js';
import { useLogger } from '../hooks/useLogger';
import { Eye, EyeOff } from 'lucide-react';
import './css/Profile.css';
import { MARKETING_SUBDOMAIN } from '../config/app';
import Modal from './Modal';

const Profile = ({ handleLogin, handleLogout, userProfile, handleSubscriptionAction, setUserProfile }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const logger = useLogger();
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);

  useEffect(() => {
    if (userProfile) {
      logger.info('Profile > User profile:', userProfile);
      setStatusMessage('');
    }
  }, [userProfile]);

  const onLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await handleLogin(email);
      setStatusMessage(result.message || t('profile.magicLinkSent'));
    } catch (error) {
      setStatusMessage(error.response?.data?.message || t('profile.loginError'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscriptionClick = async () => {
    try {
      const endpoint = userProfile.subscription_tier === 'free' ? 'CREATE_CHECKOUT_SESSION' : 'CREATE_PORTAL_SESSION';
      const response = await apiRequest('POST', endpoint);
      
      if (!response || !response.url) {
        throw new Error('Invalid session response');
      }

      // Open in new window with specific dimensions
      const width = 500;
      const height = 700;
      const left = (window.screen.width - width) / 2;
      const top = (window.screen.height - height) / 2;
      window.open(
        response.url,
        'Stripe Session',
        `width=${width},height=${height},left=${left},top=${top},location=yes,menubar=no,toolbar=no,status=no`
      );
      setIsCheckoutModalOpen(true);
    } catch (error) {
      logger.error('Error creating session:', error);
      setStatusMessage(t('profile.subscriptionError'));
    }
  };

  const handleContinueAfterCheckout = async () => {
    setIsLoadingProfile(true);
    try {
      const response = await apiRequest('GET', 'USER_PROFILE');
      if (response) {
        logger.info('Updated user profile:', response);
        setUserProfile(response);
      }
    } catch (error) {
      logger.error('Error fetching updated profile:', error);
      setStatusMessage(t('profile.updateError'));
    } finally {
      setIsLoadingProfile(false);
      setIsCheckoutModalOpen(false);
    }
  };

  return (
    <div className="profile-panel">
      {isLoading ? (
        <div className="spinner"></div>
      ) : userProfile ? (
        <>
          <div className="profile-section">
            <div className="profile-info-section">
              <div className="email-section">
                <label className="email-label">{t('profile.email')}:</label>
                <span className="email-address">{userProfile.email}</span>
              </div>
              <div className="logout-section">
                <button onClick={handleLogout} className="logout-button">
                  {t('profile.logout')}
                </button>
              </div>
            </div>
            <div className="subscription-section">
              <h3>{t('profile.subscription')}</h3>
              {userProfile.subscription_tier && (
                <div className="current-subscription">
                  <p>{t(`subscription.tier.${userProfile.subscription_tier}`)}</p>
                  {userProfile.subscription_status && <p>{t('profile.subscriptionStatus')}: {userProfile.subscription_status}</p>}
                  {userProfile.subscription_end_date && (
                    <p>{t('profile.subscriptionEndDate')}: {new Date(userProfile.subscription_end_date).toLocaleDateString()}</p>
                  )}
                </div>
              )}
              <button 
                className="subscription-button" 
                onClick={handleSubscriptionClick}
              >
                {t('profile.' + (userProfile.subscription_tier === 'free' ? 'upgradeSubscription' : 'manageSubscription'))}
              </button>
              {userProfile.subscription_tier === 'free' && (
                <div className="subscription-info">
                  <button onClick={() => window.open(`https://${MARKETING_SUBDOMAIN}/pricing`, '_blank')} className="subscription-learn-more">
                    {t('profile.learnMore')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <h3>{t('profile.magicEntry')}</h3>
          <form onSubmit={onLogin} className="login-form">
            <input
              type="email"
              placeholder={t('profile.email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">{t('profile.requestMagicLink')}</button>
          </form>
          {statusMessage && (
            <p className={`status-message ${statusMessage.includes('error') ? 'error' : 'success'}`}>
              {statusMessage.includes('error') ? `❌ ${statusMessage}` : `✅ ${statusMessage}`}
            </p>
          )}
        </>
      )}
      
      {isCheckoutModalOpen && (
        <Modal onClose={() => setIsCheckoutModalOpen(false)} showCloseButton={false}>
          <div className="checkout-modal-content">
            <h2>{t('checkout.completeProcess')}</h2>
            <p>{t('checkout.clickContinue')}</p>
            <button 
              onClick={handleContinueAfterCheckout}
              disabled={isLoadingProfile}
              className="continue-button"
            >
              {isLoadingProfile ? (
                <div className="spinner"></div>
              ) : (
                t('common.continue')
              )}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Profile;


