const getGoogleMeasurementId = () => {
    return process.env.REACT_APP_GOOGLE_MEASUREMENT_ID || '';
}

export const GOOGLE_MEASUREMENT_ID = getGoogleMeasurementId();

const getMarketingSubdomain = () => {
    return process.env.REACT_APP_MARKETING_SUBDOMAIN || '';
}

export const MARKETING_SUBDOMAIN = getMarketingSubdomain();
