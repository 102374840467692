import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../config/api.js';
import './css/common.css';
import './css/themes.css';
import './css/IssueReportModal.css';

const IssueReportModal = ({ isOpen, onClose, bookDetails, currentPageId, nativeLanguage, learningLanguage }) => {
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState('');
  const [sentiment, setSentiment] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await apiRequest('POST', 'REPORT_ISSUE', {}, {}, {
        feedback,
        sentiment,
        bookDetails: bookDetails ? {
          id: bookDetails.id,
          title: bookDetails.title
        } : null,
        currentPageId,
        nativeLanguage,
        learningLanguage
      });
      setSubmissionStatus('success');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setSubmissionStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setSubmissionStatus(null);
    setFeedback('');
    setSentiment(null);
    onClose();
  };

  const handleSentimentSelect = (selectedSentiment) => {
    console.log('selectedSentiment', selectedSentiment);
    setSentiment(selectedSentiment === sentiment ? null : selectedSentiment);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {submissionStatus === null ? (
          <>
            <h2>{t('feedbackReport.title')}</h2>
            <p>{t('feedbackReport.description')}</p>
            <form onSubmit={handleSubmit}>
              <div className="sentiment-selector">
                <button
                  type="button"
                  className={`sentiment-button ${sentiment === 'sad' ? 'selected' : ''}`}
                  onClick={() => handleSentimentSelect('sad')}
                >
                  😢
                </button>
                <button
                  type="button"
                  className={`sentiment-button ${sentiment === 'neutral' ? 'selected' : ''}`}
                  onClick={() => handleSentimentSelect('neutral')}
                >
                  😐
                </button>
                <button
                  type="button"
                  className={`sentiment-button ${sentiment === 'happy' ? 'selected' : ''}`}
                  onClick={() => handleSentimentSelect('happy')}
                >
                  😊
                </button>
              </div>
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                rows="4"
                required
              />
              <div className="modal-actions">
                <button type="button" onClick={handleClose} className="cancel-button">
                  {t('common.cancel')}
                </button>
                <button type="submit" disabled={isSubmitting} className="submit-button">
                  {isSubmitting ? t('common.submitting') : t('common.submit')}
                </button>
              </div>
            </form>
          </>
        ) : submissionStatus === 'success' ? (
          <>
            <p>{t('feedbackReport.successMessage')}</p>
            <div className="modal-actions">
              <button onClick={handleClose} className="close-button">
                {t('navigation.close')}
              </button>
            </div>
          </>
        ) : (
          <>
            <p>{t('feedbackReport.errorMessage')}</p>
            <div className="modal-actions">
              <button onClick={handleClose} className="close-button">
                {t('navigation.close')}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default IssueReportModal;