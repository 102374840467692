import React from 'react';
import './css/LoadingProgress.css';

const LoadingProgress = ({ progress, label }) => {
  return (
    <div className="loading-progress-container">
      <div 
        className="loading-progress-bar" 
        style={{ width: `${progress}%` }}
      />
      {label && <div className="loading-progress-label">{label}</div>}
    </div>
  );
};

export default LoadingProgress;