import React, { useState, useEffect } from 'react';
import { apiRequest } from '../config/api';
import { useTranslation } from 'react-i18next';
import './css/Toolbar.css';
import './css/common.css';
import IssueReportModal from './IssueReportModal';
import { useLogger } from '../hooks/useLogger';

const Toolbar = ({ 
  onToggleNativeLanguage, 
  onTogglePronunciation, 
  onToggleFurigana,
  bookDetails, 
  currentPageId, 
  currentPageNumber,
  nativeLanguage, 
  learningLanguage, 
  isContentLoaded,
  isPronunciationLoaded,
  isFuriganaLoaded,
  showFurigana,
  nativeLanguageDirection
}) => {
  const { t } = useTranslation();
  const logger = useLogger();
  const [nativeLanguageEnabled, setNativeLanguageEnabled] = useState(false);
  const [pronunciationEnabled, setPronunciationEnabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isPageLoaded = !!currentPageId;

  const handleToggleNativeLanguage = () => {
    setNativeLanguageEnabled(prev => !prev);
    onToggleNativeLanguage();
  };

  const handleTogglePronunciation = () => {
    setPronunciationEnabled(prev => !prev);
    onTogglePronunciation();
  };

  const handleToggleFurigana = () => {
    onToggleFurigana();
  };

  const handleReportIssue = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    logger.debug(`Native language direction: ${nativeLanguageDirection}`);
  }, [nativeLanguageDirection]);

  return (
    <div className="toolbar" dir={nativeLanguageDirection}>
      <button 
        className={`native-language-toggle ${nativeLanguageEnabled ? 'enabled' : ''}`}
        onClick={handleToggleNativeLanguage}
        disabled={!isContentLoaded}
      >
        {nativeLanguageEnabled ? t('toolbar.showNativeLanguage') : t('toolbar.hideNativeLanguage')}
      </button>
      <button 
        className={`pronunciation-toggle ${pronunciationEnabled ? 'enabled' : ''}`}
        onClick={handleTogglePronunciation}
        disabled={!isContentLoaded || !isPronunciationLoaded}
      >
        {pronunciationEnabled ? t('toolbar.hidePronunciation') : t('toolbar.showPronunciation')}
      </button>
      {learningLanguage === 'ja' && (
        <button 
          className={`furigana-toggle ${showFurigana ? 'enabled' : ''}`}
          onClick={handleToggleFurigana}
          disabled={!isContentLoaded || !isFuriganaLoaded}
        >
          {showFurigana ? t('toolbar.hideFurigana') : t('toolbar.showFurigana')}
        </button>
      )}
      <button 
        className="report-issue"
        onClick={handleReportIssue}
      >
        {t('toolbar.submitFeedback')}
      </button>
      <IssueReportModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        bookDetails={bookDetails}
        currentPageId={currentPageId}
        nativeLanguage={nativeLanguage}
        learningLanguage={learningLanguage}
      />
    </div>
  );
};

export default Toolbar;