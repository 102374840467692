// src/utils/logger.js

const LogLevels = {
    ERROR: 0,
    WARN: 1,
    INFO: 2,
    DEBUG: 3,
    SILLY: 4
  };
  
  class Logger {
    constructor() {
      this.level = LogLevels.INFO; // Default log level
    }
  
    setLevel(level) {
      if (LogLevels.hasOwnProperty(level)) {
        this.level = LogLevels[level];
      } else {
        console.warn(`Invalid log level: ${level}. Using default (INFO).`);
      }
    }
  
    error(...args) {
      if (this.level >= LogLevels.ERROR) console.error('[ERROR]', ...args);
    }
  
    warn(...args) {
      if (this.level >= LogLevels.WARN) console.warn('[WARN]', ...args);
    }
  
    info(...args) {
      if (this.level >= LogLevels.INFO) console.info('[INFO]', ...args);
    }
  
    debug(...args) {
      if (this.level >= LogLevels.DEBUG) console.log('[DEBUG]', ...args);
    }
  
    silly(...args) {
      if (this.level >= LogLevels.SILLY) console.log('[SILLY]', ...args);
    }
  }
  
  const logger = new Logger();
  
  export default logger;