import React from 'react';
import './css/Modal.css';

const Modal = ({ children, onClose, showCloseButton = true }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {children}
        {showCloseButton && <button onClick={onClose}>Close</button>}
      </div>
    </div>
  );
};

export default Modal;