import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { apiRequest } from '../config/api.js';
import { useLogger } from '../hooks/useLogger';
import './css/Modal.css';

const ProductPromotionModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const logger = useLogger('ProductPromotionModal');

  if (!isOpen) return null;

  const handleUpgrade = async () => {
    try {
      const response = await apiRequest('CREATE_CHECKOUT_SESSION');
      window.location.href = response.data.url;
    } catch (error) {
      logger.error('Error creating checkout session:', error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>{t('productPromotion.title')}</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">
          <p>{t('productPromotion.description')}</p>
          <button className="modal-button" onClick={onClose}>{t('navigation.close')}</button>
          <button className="modal-button" onClick={handleUpgrade}>
            {t('productPromotion.upgrade')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductPromotionModal;