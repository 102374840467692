import React from 'react';
import { Check } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Settings = ({ 
  currentTheme, 
  onThemeChange, 
  textAlignment, 
  onTextAlignmentChange, 
  dyslexicFontEnabled, 
  onDyslexicFontToggle, 
  onOpenCookiePreferences,
}) => {
  const { t } = useTranslation();

  const themeOptions = [
    {
      name: 'classic',
      colors: {
        primary: '#f4efe1',
        secondary: '#8b7d6b',
        accent: '#b8a88a',
      },
    },
    {
      name: 'dark',
      colors: {
        primary: '#1a1a2e',
        secondary: '#e94560',
        accent: '#0f3460',
      },
    },
    {
      name: 'vibrant',
      colors: {
        primary: '#ffffff',
        secondary: '#ff6b6b',
        accent: '#4ecdc4',
      },
    },
  ];

  return (
    <div className="settings-panel">
      <div className="menu-section">
        <h3>{t('menu.theme')}</h3>
        <div className="theme-options">
          {themeOptions.map((theme) => (
            <div
              key={theme.name}
              className={`theme-option ${currentTheme === theme.name ? 'active' : ''}`}
              onClick={() => onThemeChange(theme.name)}
            >
              <div className="theme-colors">
                <div style={{ backgroundColor: theme.colors.primary }} />
                <div style={{ backgroundColor: theme.colors.secondary }} />
                <div style={{ backgroundColor: theme.colors.accent }} />
              </div>
              {currentTheme.toLowerCase() === theme.name.toLowerCase() && (
                <Check size={16} className="theme-check" />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="menu-section">
        <h3>{t('settings.textAlignment')}</h3>
        <select onChange={onTextAlignmentChange} value={textAlignment} className="filter-select">
          <option value="justify-left">{t('settings.leftJustify')}</option>
          <option value="justify-right">{t('settings.rightJustify')}</option>
          <option value="justify-center">{t('settings.center')}</option>
          <option value="interlace">{t('settings.interlace')}</option>
        </select>
      </div>
      <div className="menu-section">
        <h3>{t('settings.dyslexicFont')}</h3>
        <button 
          className={`dyslexic-font-toggle ${dyslexicFontEnabled ? 'enabled' : ''}`} 
          onClick={onDyslexicFontToggle} 
          style={{fontFamily: 'OpenDyslexic', fontSize: '16px'}}
        >
          {dyslexicFontEnabled ? t('settings.disableDyslexicFont') : t('settings.enableDyslexicFont')}
        </button>
      </div>
      <div className="menu-section">
        <h3>{t('settings.cookiePreferences')}</h3>
        <button 
          className="cookie-preferences-button" 
          onClick={onOpenCookiePreferences}
        >
          {t('settings.openCookiePreferences')}
        </button>
      </div>
    </div>
  );
};

export default Settings;