import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './css/CookieNotice.css';
import { GOOGLE_MEASUREMENT_ID } from '../config/app';

const removeAnalyticsCookies = () => {
  const cookiesToRemove = ['_ga', '_gid', '_gat'];
  cookiesToRemove.forEach(cookieName => {
    document.cookie = `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  });
};

const applyCookiePreferences = (preferences) => {
  if (!preferences.analytics) {
    window[`ga-disable-${GOOGLE_MEASUREMENT_ID}`] = true;
    removeAnalyticsCookies();
  } else {
    window[`ga-disable-${GOOGLE_MEASUREMENT_ID}`] = false;
  }
  localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
};

const CookieNotice = ({ show, onClose, onSavePreferences, initialPreferences, showCustomize }) => {
  const [cookiePreferences, setCookiePreferences] = useState(initialPreferences);
  const [showPreferences, setShowPreferences] = useState(showCustomize);
  const { t } = useTranslation();

  useEffect(() => {
    setShowPreferences(showCustomize);
  }, [showCustomize]);

  const handleAcceptAll = () => {
    const allAccepted = {
      necessary: true,
      analytics: true
    };
    applyCookiePreferences(allAccepted);
    onSavePreferences(allAccepted);
  };

  const handleRejectAll = () => {
    const allRejected = {
      necessary: true,
      analytics: false
    };
    applyCookiePreferences(allRejected);
    onSavePreferences(allRejected);
  };

  const handleCustomize = () => {
    setShowPreferences(true);
  };

  const handleSavePreferences = () => {
    onSavePreferences(cookiePreferences);
  };

  const handlePreferenceChange = (e) => {
    setCookiePreferences({
      ...cookiePreferences,
      [e.target.name]: e.target.checked
    });
  };

  if (!show) return null;

  return (
    <div className="cookie-notice-overlay">
      <div className="cookie-notice">
        <p>{t('cookieNotice.mainText')}</p>
        {!showPreferences ? (
          <>
            <button onClick={handleAcceptAll}>{t('cookieNotice.acceptAll')}</button>
            <button onClick={handleRejectAll}>{t('cookieNotice.rejectAll')}</button>
            <button onClick={handleCustomize}>{t('cookieNotice.customize')}</button>
          </>
        ) : (
          <div className="cookie-preferences">
            <h3>{t('cookieNotice.customizeTitle')}</h3>
            <label>
              <input
                type="checkbox"
                name="necessary"
                checked={cookiePreferences.necessary}
                onChange={handlePreferenceChange}
                disabled
              />
              {t('cookieNotice.necessaryCookies')}
            </label>
            <label>
              <input
                type="checkbox"
                name="analytics"
                checked={cookiePreferences.analytics}
                onChange={handlePreferenceChange}
              />
              {t('cookieNotice.analyticsCookies')}
            </label>
            <button onClick={handleSavePreferences}>{t('cookieNotice.savePreferences')}</button>
          </div>
        )}
        <p className="cookie-notice-footer">
          <a href="/privacy-policy">{t('cookieNotice.privacyPolicy')}</a>
          <a href="/cookie-policy">{t('cookieNotice.cookiePolicy')}</a>
          <a href="/terms-of-service">{t('cookieNotice.termsOfService')}</a>
        </p>
      </div>
    </div>
  );
};

export default CookieNotice;