import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Billing = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  return (
    <div className="policy-container">
      <h1>{t(`stripe.payment${status === 'failed' ? 'Failure' : 'Success'}.title`)}</h1>
      <p>{t(`stripe.payment${status === 'failed' ? 'Failure' : 'Success'}.message`)}</p>
    </div>
  );
};

export default Billing;