import React from 'react';

const TranslationBlocker = (languageId) => {
  React.useEffect(() => {
    document.documentElement.lang = languageId;
    document.documentElement.translate = 'no';
  }, [languageId]);

  return (
    <meta name="google" content="notranslate" />
  );
};

export default TranslationBlocker;