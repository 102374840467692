// src/hooks/useLogger.js
import { useCallback } from 'react';
import logger from '../utils/logger';

export function useLogger(prefix = '') {
  const log = useCallback(
    (level, ...args) => {
      if (prefix) {
        logger[level](prefix, ...args);
      } else {
        logger[level](...args);
      }
    },
    [prefix]
  );

  return {
    error: (...args) => log('error', ...args),
    warn: (...args) => log('warn', ...args),
    info: (...args) => log('info', ...args),
    debug: (...args) => log('debug', ...args),
    silly: (...args) => log('silly', ...args),
  };
}