import React, { useEffect } from 'react';
import './css/ThemePickerWelcomeScreen.css';
import { useTranslation } from 'react-i18next';

const ThemePickerWelcomeScreen = ({ onThemeSelect, onClose }) => {
  const { t } = useTranslation();
  
  const themes = [
    { name: t('theme.dark'), primaryColor: '#2c2c2c', secondaryColor: '#6c5ce7' },
    { name: t('theme.classic'), primaryColor: '#f4efe1', secondaryColor: '#8b7d6b' },
    { name: t('theme.vibrant'), primaryColor: '#ffffff', secondaryColor: '#ff6b6b' },
  ];

  return (
    <div className="theme-picker-welcome-screen">
      <h1>{t('theme.welcome')}</h1>
      <h2>{t('theme.pick')}:</h2>
      <div className="theme-options">
        {themes.map((theme) => (
          <div
            key={theme.name}
            className="theme-option-welcome"
            style={{
              backgroundColor: theme.primaryColor,
              border: `2px solid ${theme.secondaryColor}`,
              marginBottom: '1em',
              padding: '0.5em',
            }}
            onClick={() => onThemeSelect(theme.name)}
          >
            <span 
              style={{ 
                color: theme.secondaryColor,
                wordBreak: 'break-word',
                hyphens: 'auto',
              }}
            >
              {theme.name}
            </span>
          </div>
        ))}
      </div>
      <p style={{ fontSize: '0.8em', color: '#888', marginTop: '1em' }}>{t('theme.changeLater')}</p>
      {/* <button className="enter-button" onClick={onClose}>{t("navigation.enter")}</button> */}
    </div>
  );
};

export default ThemePickerWelcomeScreen;