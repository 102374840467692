import axios from 'axios';

const getApiBaseUrl = () => {
  if (process.env.REACT_APP_NODE_ENV === 'prd' || process.env.REACT_APP_NODE_ENV === 'stg') {
    return `https://${process.env.REACT_APP_API_SUBDOMAIN}`;
  } else {
    return 'http://localhost:5000';
  }
};

export const API_BASE_URL = getApiBaseUrl();

export const API_ENDPOINTS = {
  AUDIO_PRONUNCIATION: '/api/audio_pronunciation',
  BOOKS: '/api/books',
  BOOK: '/api/books/:book_id',
  BOOK_PAGE: '/api/books/:book_id/page/:page_id',
  BOOK_PAGE_ASSOCIATIONS: '/api/books/:book_id/page/:page_id/associations',
  BOOK_PAGE_PRONUNCIATION: '/api/books/:book_id/page/:page_id/phonetic_transcription',
  BOOK_FIRST_PAGE: '/api/books/:book_id/first_page',
  BOOK_NEXT_PAGE: '/api/books/:book_id/page/:page_id/next',
  BOOK_PREVIOUS_PAGE: '/api/books/:book_id/page/:page_id/previous',
  BOOK_PAGE_BY_NUMBER: '/api/books/:book_id/page',
  LANGUAGE_OPTIONS: '/api/languages/options',
  PROMPT: '/api/prompt',
  WORD_DATA: '/api/definitions',
  VOTE: '/api/books/:book_id/vote',
  EMOJI_VOTE: '/api/books/:book_id/emoji_vote',
  USER_FAVORITES: '/api/user/favorites',
  USER_FAVORITE: '/api/user/favorites/:book_id',
  USER_EMAIL: '/api/user/email',
  USER_UPLOAD_PROFILE_PICTURE: '/api/user/picture:upload',
  REPORT_ISSUE: '/api/reports',
  CREATE_CHECKOUT_SESSION: '/api/stripe/create-checkout-session',
  CREATE_PORTAL_SESSION: '/api/stripe/portal-session',
  LOGIN: '/api/auth/request-magic-link',
  MAGIC_LINK_CONFIRM: '/api/auth/confirm-email',
  LOGOUT: '/api/auth/logout',
  USER_PROFILE: '/api/auth/profile',
  FURIGANA_TRANSCRIPTION: '/api/books/:book_id/page/:page_id/furigana_transcription',
  USER_BOOKMARKS: '/api/user/bookmarks',
  USER_BOOKMARK: '/api/user/bookmarks/:book_id/:page_id',
  CONFIRM_EMAIL: '/api/confirm-email',
};

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

export const setUserLocaleHeader = (locale) => {
  axiosInstance.defaults.headers.common['X-User-Locale'] = locale;
};

export const getApiUrl = (endpoint, pathParams = {}, queryParams = {}) => {
  let url = `${API_BASE_URL}${API_ENDPOINTS[endpoint]}`;
  
  // Replace path parameters
  Object.keys(pathParams).forEach(key => {
    url = url.replace(`:${key}`, encodeURIComponent(pathParams[key]));
  });

  // Add query parameters
  const queryString = new URLSearchParams(queryParams).toString();
  if (queryString) {
    url += `?${queryString}`;
  }

  return url;
};

export const apiRequest = async (method, endpoint, pathParams = {}, queryParams = {}, data = null, signal = null) => {
  const url = getApiUrl(endpoint, pathParams, queryParams);
  try {
    const response = await axiosInstance({
      method,
      url,
      data,
      signal,
    });
    return response.data;
  } catch (error) {
    console.error(`API request error (${method} ${url}):`, error);
    throw error;
  }
};