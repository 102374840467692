import ReactGA4 from 'react-ga4';

import { GOOGLE_MEASUREMENT_ID } from './config/app';

const initGA = () => {
  ReactGA4.initialize(GOOGLE_MEASUREMENT_ID, {
    debug_mode: process.env.NODE_ENV !== 'production'
  });
}

const logPageView = () => {
  ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
}

export { initGA, logPageView };