import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CookiePolicy = () => {
  const { t, i18n } = useTranslation();

  const userLocale = localStorage.getItem('nativeLanguage');

  useEffect(() => {
    if (userLocale) {
      i18n.changeLanguage(userLocale);
    }
    document.title = "ABAL | " + t('cookiePolicy.title');
  }, [userLocale, i18n, t]);

  return (
    <div className="policy-container">
      <h1>{t('cookiePolicy.title')}</h1>
      <p>{t('cookiePolicy.introduction')}</p>
      <h2>{t('cookiePolicy.whatAreCookies')}</h2>
      <p>{t('cookiePolicy.cookiesExplanation')}</p>
      <h2>{t('cookiePolicy.howWeUseCookies')}</h2>
      <p>{t('cookiePolicy.cookiesUsage')}</p>
      <h2>{t('cookiePolicy.managingCookies')}</h2>
      <p>{t('cookiePolicy.cookiesManagement')}</p>
    </div>
  );
};

export default CookiePolicy;