import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { I18nextProvider, useTranslation } from 'react-i18next';

import { apiRequest } from './config/api';
import i18n from './i18n';

import { initGA, logPageView } from './analytics';

import ParallelTextViewer from './components/ParallelTextViewer';
import CookieNotice from './components/CookieNotice';
import CookiePolicy from './components/CookiePolicy';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Modal from './components/Modal';
import NotFound from './components/NotFound';
import Billing from './components/Billing';

import TranslationBlocker from './TranslationBlocker';

import { useLogger } from './hooks/useLogger';

import './App.css';

const AppContent = () => {
  const logger = useLogger();
  const [languageId, setLanguageId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [confirmEmailMessage, setConfirmEmailMessage] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [showCookieNotice, setShowCookieNotice] = useState(false);
  const [showCookiePreferences, setShowCookiePreferences] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true,
    analytics: false,
  });

  const [magicLinkStatus, setMagicLinkStatus] = useState(null);
  const [shouldTriggerSubscribe, setShouldTriggerSubscribe] = useState(false);

  const handleLanguageChange = (languageId) => {
    logger.debug(`Language changed to: ${languageId}`);
    setLanguageId(languageId);
  };

  useEffect(() => {
    document.title = "All Books, All Languages";

    const urlParams = new URLSearchParams(location.search);
    const status = urlParams.get('status');
    const emailToken = urlParams.get('token');
    const path = location.pathname;
    const action = urlParams.get('action');

    if (path === '/auth/magic-link' && emailToken) {
      handleMagicLinkConfirmation(emailToken);
    } else if (emailToken) {
      handleEmailConfirmation(emailToken);
    } else if (action === 'subscribe') {
      logger.info('Subscribing to updates');
      setShouldTriggerSubscribe(true);
      navigate(location.pathname, { replace: true });
    }
  }, [location]);

  const handleEmailConfirmation = async (token) => {
    try {
      const response = await apiRequest('POST', 'CONFIRM_EMAIL', {}, {}, { token });
      setConfirmEmailMessage(response.message);
      setShowModal(true);
    } catch (error) {
      setConfirmEmailMessage(t('emailConfirmation.error'));
      setShowModal(true);
    }
    navigate(location.pathname, { replace: true });
  };

  const handleMagicLinkConfirmation = async (token) => {
    setShowModal(true);
    setMagicLinkStatus('loading');

    try {
      const response = await apiRequest('POST', 'MAGIC_LINK_CONFIRM', {}, {}, { token });
      setMagicLinkStatus('success');
      setTimeout(() => {
        navigate('/', { replace: true });
        setShowModal(false);
        setMagicLinkStatus(null);
      }, 2000);
    } catch (error) {
      logger.error('Magic link confirmation failed:', error);
      setMagicLinkStatus('error');
      navigate('/', { replace: true });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPaymentStatus(null);
    setConfirmEmailMessage(null);
  };

  useEffect(() => {
    const preferences = localStorage.getItem('cookiePreferences');
    if (preferences) {
      setShowCookieNotice(false);
      setCookiePreferences(JSON.parse(preferences));
      initAnalytics(JSON.parse(preferences));
    }else {
      setShowCookieNotice(true);
    }
  }, []);

  const handleCookiePreferencesChange = (preferences) => {
    logger.info(`Cookie preferences changed: ${JSON.stringify(preferences)}`);
    setCookiePreferences(preferences);
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    setShowCookieNotice(false);
    setShowCookiePreferences(false);
    initAnalytics(preferences);
  };

  const initAnalytics = (preferences) => {
    if (preferences.analytics) {
      initGA();
      logPageView();
    }
  };

  const handleCookieNoticeClose = () => {
    setShowCookieNotice(false);
  };

  const openCookiePreferences = () => {
    setShowCookiePreferences(true);
  };

  return (
    <>
      <TranslationBlocker languageId={languageId} />
      <Routes>
        <Route path="/auth/magic-link" element={
          <ParallelTextViewer 
            languageId={languageId} 
            onLanguageChange={handleLanguageChange} 
            openCookiePreferences={openCookiePreferences}
            magicLinkStatus={magicLinkStatus}
          />
        } />
        <Route path="/" element={
          <ParallelTextViewer 
            languageId={languageId} 
            onLanguageChange={handleLanguageChange} 
            openCookiePreferences={openCookiePreferences}
            magicLinkStatus={magicLinkStatus}
            shouldTriggerSubscribe={shouldTriggerSubscribe}
            onSubscribeTriggered={() => setShouldTriggerSubscribe(false)}
          />
        } />
        <Route path="/profile" element={
          <ParallelTextViewer 
            languageId={languageId} 
            onLanguageChange={handleLanguageChange} 
            openCookiePreferences={openCookiePreferences}
            magicLinkStatus={magicLinkStatus}
            shouldTriggerSubscribe={shouldTriggerSubscribe}
            onSubscribeTriggered={() => setShouldTriggerSubscribe(false)}
          />
        } />
        <Route path="/settings" element={
          <ParallelTextViewer 
            languageId={languageId} 
            onLanguageChange={handleLanguageChange} 
            openCookiePreferences={openCookiePreferences}
            magicLinkStatus={magicLinkStatus}
            shouldTriggerSubscribe={shouldTriggerSubscribe}
            onSubscribeTriggered={() => setShouldTriggerSubscribe(false)}
          />
        } />
        <Route path="/cookie-policy" element={<CookiePolicy/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/terms-of-service" element={<TermsOfService/>} />
        <Route path="/billing" element={<Billing/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {showModal && (
        <Modal onClose={handleCloseModal}>
          {magicLinkStatus === 'loading' ? (
            <div className="loading-animation">
              <div className="spinner"></div>
              <p>{t('auth.magicLink.verifying')}</p>
            </div>
          ) : magicLinkStatus === 'success' ? (
            <div>
              <h2>{t('auth.magicLink.success.title')}</h2>
              <p>{t('auth.magicLink.success.message')}</p>
            </div>
          ) : magicLinkStatus === 'error' ? (
            <div>
              <h2>{t('auth.magicLink.error.title')}</h2>
              <p>{t('auth.magicLink.error.message')}</p>
            </div>
          ) : paymentStatus === 'success' ? (
            <div>
              <h2>{t('stripe.paymentSuccess.title')}</h2>
              <p>{t('stripe.paymentSuccess.message')}</p>
            </div>
          ) : paymentStatus === 'failure' ? (
            <div>
              <h2>{t('stripe.paymentFailure.title')}</h2>
              <p>{t('stripe.paymentFailure.message')}</p>
            </div>
          ) : confirmEmailMessage ? (
            <div>
              <h2>{t('emailConfirmation.title')}</h2>
              <p>{confirmEmailMessage}</p>
            </div>
          ) : null}
        </Modal>
      )}
      {(showCookieNotice || showCookiePreferences) && (
        <CookieNotice
          show={showCookieNotice || showCookiePreferences}
          onClose={handleCookieNoticeClose}
          onSavePreferences={handleCookiePreferencesChange}
          initialPreferences={cookiePreferences}
          showCustomize={showCookiePreferences}
        />
      )}
    </>
  );
};

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <AppContent />
      </Router>
    </I18nextProvider>
  );
};

export default App;